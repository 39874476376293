<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div v-if="maquinas.length" class="container-pages">
					<b-pagination
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<div
					v-if="possuiPermissao('GER_I_MAQUINAS')"
					class="container-add-new"
					v-bind:class="!maquinas.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao()">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="maquinas.length" class="head-row">
				<span class="text-head-row ml-1">
					Resultados {{ pagination.offset + 1 }}-{{ pagination.offset + maquinas.length }} de {{ pagination.count }}
				</span>
				<b-form
					class="text-head-row">
					<label class="mr-2">
						Itens por página:
					</label>
					<b-form-select
						class="mr-1 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				:title="titleModalEdicao"
				size="md"
				dialog-class="modal-dialog-centered"
			>
				<div class="modal-edicao d-flex mt-3 mb-3 modal-edicao-label">
					<b-col cols="9" class="d-block text-modal pr-1 pl-0">
						<span class="label-modal">Nome da Injetora</span>
						<b-form-input
							name="nome_injetora"
							class="text-center modal-edicao-input"
							type="text"
							v-model="maquinaSelecionada.nome"
							autocomplete="off"
							v-validate="{ required: true }"
							:state="validateState('nome_injetora')"
						/>
					</b-col>
					<b-col cols="3" class="d-block text-modal pl-1 pr-0">
						<span class="label-modal">Ordem</span>
						<b-form-input
							class="text-center modal-edicao-input custom-number-input"
							name="ordem_injetora"
							type="number"
							v-model="maquinaSelecionada.ordem"
							autocomplete="off"
							min="1"
							v-validate="{ required: false, min: 1, min_value: 1 }"
							:state="validateState('ordem_injetora')"
						/>
					</b-col>
				</div>
				<template #modal-footer>
					<div class="w-100 d-flex justify-content-between">
						<div class="btn-group">
							<b-button class="btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
							<b-button class="btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
						</div>
						<b-button
							class="btn-modal btn-salvar"
							@click="salvarEdicao"
							:disabled="invalidForm"
						>
							Salvar
						</b-button>
					</div>
				</template>
			</b-modal>
		</div>
		<div v-if="maquinas.length" class="table-responsive">
			<DataTable
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="maquinas"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_MAQUINAS')"
				:permissaoDelete="possuiPermissao('GER_D_MAQUINAS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { MaquinasService } from "../../services/maquinas";
	import { possuiPermissao } from "../../helpers/permissions";

	export default {
		components: {
			TemplateBase,
			DataTable
		},

		inject: ["parentValidator"],

		data () {
			return {
				maquinas: [],
				linhas: [],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				titleModalEdicao: "",
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Itens"
					},
					{
						value: 25,
						text: "25 Itens"
					},
					{
						value: 50,
						text: "50 Itens"
					},
					{
						value: 75,
						text: "75 Itens"
					},
					{
						value: 100,
						text: "100 Itens"
					}
				],
				loading: true,
				maquinaSelecionada: {
					id: "",
					nome: "",
					ordem: ""
				},
				invalidForm: true,
				maquinasService: new MaquinasService(),

				errMsg: "Nenhuma injetora cadastrada!",
				colunas: [
					"Injetora",
					"Ordem"
				],

				possuiPermissao
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.findMachines();
		},

		methods: {
			changePage () {
				this.pagination.page = this.currentPage;
				this.findMachines();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findMachines();
			},

			async findMachines () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;

				const result = await this.maquinasService.listMachinesWithPagination({offset, limit}) || {};
				this.maquinas = result.rows || [];
				this.linhas = result.rows.reduce((acc, maquina) => [
					...acc,
					{
						id: maquina.id,
						cols: [
							maquina.nome,
							maquina.ordem || ""
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			mostrarEdicao (item) {
				let itemEdit;
				this.invalidForm = true;
				this.titleModalEdicao = "Cadastrar Injetora";

				if (item && item.id) {
					itemEdit = this.maquinas.find(maquina => maquina.id === item.id);
					this.titleModalEdicao = "Editar Injetora";
				}

				this.maquinaSelecionada.id = itemEdit?.id || "";
				this.maquinaSelecionada.nome = itemEdit?.nome || "";
				this.maquinaSelecionada.ordem = itemEdit?.ordem || "";
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const item = this.maquinas.find(maquina => maquina.id === item_.id);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover a injetora?",
					type: "warning",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.id);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					nome: this.maquinaSelecionada.nome,
					ordem: this.maquinaSelecionada.ordem
				};

				// ID será enviado apenas se necessário
				if (Number.isInteger(this.maquinaSelecionada.id)) {
					payload.id = this.maquinaSelecionada.id;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.maquinasService.upsertMachine(payload);

						this.fecharModal("modalEdicao");
						this.findMachines();

						return {
							title: "Sucesso!",
							body: "Injetora salva com sucesso!",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar salvar a injetora!",
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (id) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.maquinasService.deleteMachine(id);
						this.findMachines();

						return {
							title: "Sucesso!",
							body: "Máquina apagada com sucesso.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao tentar apagar a máquina.",
							config
						};
					}
				});
			},

			limparModal () {
				this.maquinaSelecionada.nome = "";
				this.maquinaSelecionada.ordem = "";
			},

			validateState (ref) {
				if (
					this.veeFields[ref]
					&& ( this.veeFields[ref].dirty || this.veeFields[ref].validated )
				) {
					this.invalidForm = Boolean(this.veeErrors.items.length);
					return !this.veeErrors.has(ref);
				}
				return null;
			}
		}
	};
</script>

<style scoped>
	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	thead tr th {
		border-bottom: none;
	}

	tbody tr td {
		height: 45px;
	}

	.container-pages {
		display: flex;
		width: 70%;
	}

	.container-add-new {
		display: flex;
		width: 30%;
		height: 3rem;
		justify-content: right;
	}

	.container-add-new-100 {
		width: 100% !important;
	}

	.add-button {
		width: 135;
		height: 43;
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
		font-weight: 700;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
	}

	.modal-edicao-label {
		font-size: 23px;
	}

	.modal-edicao-input {
		font-size: 1rem;
	}

	.custom-number-input {
		padding-right: 35px !important;
	}

	.btn-modal {
		font-weight: 400;
		font-size: 1rem;
		color: white;
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.btn-limpar {
		background-color: #6C757D;
		border-radius: 0 4px 4px 0;
		border-style: none;

	}

	.btn-salvar {
		background-color: #28A745;
		width: 168px;
		border-radius: 4px;
		border-style: none;
	}

	.text-modal {
		font-size: 1rem;
	}

	.label-modal {
		color: #000000 !important;
	}
</style>
