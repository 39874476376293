import { HttpInterceptorsConfig } from "./httpInterceptors";

export class MaquinasService extends HttpInterceptorsConfig {
	async getAllMachinesIncludeOrdemProducao () {
		const response = await this.axios.get("machines");
		return response.data;
	}

	async findOneWithDataProduction (filters) {
		const response = await this.axios.get("machines/findOneWithDataProduction", { params: { filters }});
		return response.data;
	}

	async listAllMachines () {
		const response = await this.axios.get("machines/listAllMachines");
		return response.data;
	}

	async listMachinesWithPagination (filters) {
		const response = await this.axios.get("machines/listMachinesWithPagination", { params: { filters }});
		return response.data;
	}

	async deleteMachine (id) {
		const response = await this.axios.delete("machines/deleteMachine", { params: { id }});
		return response.data;
	}

	async upsertMachine (payload) {
		const response = await this.axios.post("machines/upsertMachine", payload);
		return response.data;
	}
}
